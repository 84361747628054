<template>
  <div class="landing container-m">
    <div class="header">
<!--      <h3>{{t("{0} days free trial", freeTrialDays)}}</h3>-->
<!--      <div class="boxes light">-->
<!--        <div class="box centered">-->
<!--        <h1>{{t("Important Message")}}</h1>-->
<!--        <p>{{t("Due to the current situation, we are offering our basic course free of charge to everybody.")}}</p>-->
<!--        <p>{{t("We hope that this will help you to stay healthy and fit during these difficult times.")}}</p>-->
<!--        </div>-->
<!--      </div>-->
      <div class="header-logo"><img :src="Logo" /></div>
      <div class="centered">
        <br /><br />
        <h2>{{t("Welcome to Ira's Commando")}}</h2>
        <p>{{t("Your way to get into a healthy lifestyle")}}</p>
      </div>
    </div>
    <div class="body">
      <router-link to="/quiz" class="btn">{{t("Let's Start!")}}</router-link>
    </div>
    <div class="footer">
      <a href="/terms" target="_blank">{{t("Terms of Use / Privacy Policy")}}</a>
    </div>
  <PoweredBy />
  </div>
</template>

<script>
import t from '@/lib/Locale'
import Logo from '@/assets/images/ira512.png';
import Fito from "@/lib/fito";
import PoweredBy from "@/components/ui/widgets/PoweredBy.vue";

export default {
name: "Landing",
  components: {PoweredBy},
  async mounted() {
    if (localStorage.getItem('login-token')) {
      const login = await Fito().Login.CheckLogin();
      if (login && login.subscriber) window.location.href = '/plan/yoga';
      return false;
    }
  },
  data() {
    return {
      Logo,
      freeTrialDays: 0
    }
  },
  methods : {
    t,
    start() {
      this.$router.push('/quiz')
    }
  }
}
</script>
