<template>
  <div :class="'navbar'+(loggedIn?' logged-in':'')+(isResults?' results-navbar':'')">
    <div class="navbar-inner">
      <div class="menu-btn" @click="toggleMenu"><img :src="isResults?menuIconWhite:menuIcon" /></div>
      <div class="navbar-logo">
        <router-link :to="logoLink"><img :src="isResults?logoWhite:logo" /></router-link>
      </div>
      <div class="user-area">
        <router-link v-if="loggedIn" class="user-icon" to="/plan/account">
          <img :src="womanIcon" />
        </router-link>
      </div>
      <MainMenu :active="menuOpen" :close-menu="closeMenu" :logged-in="loggedIn" :subscriber="subscriber" :on-logout="onLogout" />
    </div>
    <MemberMenu v-if="loggedIn" />
  </div>
</template>

<script>

import logo from '@/assets/images/headline.png';
import logoWhite from '@/assets/images/headline-white.png';
import menuIcon from '@/assets/icons/menu.svg';
import menuIconWhite from '@/assets/icons/menu-white.svg';
import womanIcon from '@/assets/icons/menu/woman.svg';

import t from '@/lib/Locale';
import MainMenu from "@/components/ui/navbar/MainMenu";
import MemberMenu from "@/components/ui/navbar/PlanMenu";


export default {
  name: "Navbar",
  components: {MemberMenu, MainMenu},
  props: ['isLanding','isResults','loggedIn','subscriber','onLogout'],
  data() {
    return {
      logo, logoWhite,
      menuIcon, menuIconWhite,
      womanIcon,

      menuOpen: false
    }
  },

  computed : {
    logoLink() {
      return this.loggedIn ? '/plan/yoga' : '/'
    }
  },

  methods : {
    t,
    toggleMenu() {
      this.menuOpen = !this.menuOpen
    },
    closeMenu() { this.menuOpen = false }
  }
}
</script>
