<template>
  <div class="view-content view-quiz">
    <stages-bar v-if="active" :quiz-index="quizIndex" :questions="questions" :on-change="onChange" />
    <div class="questions-slider">
      <div :class="'slider-inner' + (prev?' prev':'')">
        <div class="prev" v-if="prev">
          <QuizQuestion v-if="prev && active" :on-answer="onAnswer" :results="answers" :goto="goto" :on-toggle-multi-answer="onToggleMultiAnswer" :amount="prev.amount" :mandatory="prev.mandatory" :prop="prev.prop" :toggle-all="prev.toggleAll"
                     :question="prev.question" :type="prev.type" :answers="prev.answers" :info="prev.info" :min="prev.min" :max="prev.max" />
        </div>
        <div class="current">
          <QuizQuestion v-if="question && active" :on-answer="onAnswer" :results="answers" :goto="goto" :on-toggle-multi-answer="onToggleMultiAnswer" :amount="question.amount" :mandatory="question.mandatory" :prop="question.prop" :toggle-all="question.toggleAll"
                     :question="question.question" :type="question.type" :answers="question.answers" :info="question.info" :min="question.min" :max="question.max"
          />
        </div>
      </div>
    </div>
    <LoadingResults v-if="loadingResults" />
    <PoweredBy />
  </div>
</template>

<script>
import questions from "@/lib/docs/SignupQuizQuestions";
import StagesBar from "@/components/ui/StagesBar";
import QuizQuestion from "@/components/quiz/QuizQuestion";
import LoadingResults from "@/components/quiz/LoadingResults";
import t from '@/lib/Locale';
import PoweredBy from "@/components/ui/widgets/PoweredBy.vue";

export default {
  name: "Quiz",
  props: ['stats'],
  components: {PoweredBy, StagesBar, QuizQuestion, LoadingResults },
  data() {
    return {
      prev: null,
      quizIndex : 0,
      loadingResults : false,
      confirmed : false,
      pseudoLoaded : false,
      questions,
      answers : {},
      checkInterval: 4000,
      active: false
    }
  },
  computed : {
    question() { return this.questions[this.quizIndex] }
  },

  async mounted() {
    if (localStorage.getItem('login-token')) {
      // window.location.href = '/plan/yoga';
    }

    setTimeout(() => this.active = true, 250)
    this.next = (this.quizIndex < this.questions.length -1) ? this.questions[this.quizIndex+1] : null

  },

  methods : {
    t,
    goto(prop) {
      for (let i = 0; i < this.questions.length; i++) {
        if (this.questions[i].prop === prop) {
          this.quizIndex = i;
          return;
        }
      }
    },
    onToggleMultiAnswer(answer) {
      console.log({answer})
      if (!this.answers[this.question.prop]) this.answers[this.question.prop] = [];
      const index = this.answers[this.question.prop].indexOf(answer);
      if (index >= 0) this.answers[this.question.prop].splice(index,1);
      else this.answers[this.question.prop].push(answer);
      this.$set(this.answers, this.question.prop, this.answers[this.question.prop])
    },
    onAnswer(answer) {
      this.answers[this.question.prop] = answer;

      if (this.quizIndex < this.questions.length -1) {
        this.prev = {...this.question}
        this.quizIndex++;
        this.next = (this.quizIndex < this.questions.length -1) ? this.questions[this.quizIndex+1] : null
        if (this.question.condition) {
          while (this.question.condition && !this.question.condition(this.answers)) {
            this.quizIndex++;
            this.next = (this.quizIndex < this.questions.length -1) ? this.questions[this.quizIndex+1] : null
          }
        }
        setTimeout(() => this.prev = null, 501)
      }
      else {
        this.loadingResults = true;
        this.checkInterval = 4000;
        this.waitForConfirmation();
      }
    },

    waitForConfirmation() {

      localStorage.setItem('QuizAnswers', JSON.stringify(this.answers))

      setTimeout(async() => {
          const a = JSON.parse(localStorage.getItem('QuizAnswers'))
          if (a.goal && a.goal.length) await this.$router.push("/results");
          else {
            alert("Error 194: Could not save quiz!")
          }
      }, this.checkInterval);
    },

    onChange(index) {
      if (index >= 0 && index < this.questions.length) this.quizIndex = index;
    }
  }
}
</script>
