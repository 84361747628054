<template>
  <div :class="'phone-pop'+(expanded?' open':'')" v-if="notifications.length">
    <div class="phone-badge" @click="togglePhone">
      <div class="count" v-if="notifications.length - done.length > 0">{{notifications.length - done.length}}</div>
      <img :src="NotificationsBadgeImage" />
    </div>
    <div class="phone-inner" v-if="expanded">
      <div class="phone-screen">
        <div class="phone-screen-header">
          <div class="close-messages" @click="togglePhone"><img :src="xIcon" /></div>
          <div>
          <span>{{t("Unread Messages from ira:")}}</span><b>{{notifications.length - done.length}}</b>
          </div>
          <img :src="NotificationsBadgeImage" />
        </div>
        <div class="phone-message" v-for="(notification, ii) in notifications" :key="ii">
          <div class="msg-icon">
            <img class="audio-icon" :src="donePlaying(ii)?AudioMessageReadIcon:AudioMessageIcon" />
            <img :src="NotificationsBadgeImage" />
          </div>
          <div class="play-position">
            <PhoneAudioBars />
            <div :class="'play-position-inner'+(donePlaying(ii)?' done':'')" :style="{right: timePos[ii]+'%'}"></div>
          </div>
          <div class="play-button" @click="() => onPlay(notification, ii)">
            <img :src="playingNow === ii ? PauseIcon : PlayIcon" />
          </div>
          <audio :src="hibanot" ref="player" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import t from "@/lib/Locale";
import NotificationsBadgeImage from "@/assets/images/notifications-badge.png";
import AudioMessageIcon from "@/assets/icons/notifications/audio-message.png";
import AudioMessageReadIcon from "@/assets/icons/notifications/audio-message-read.png";
import PlayIcon from "@/assets/icons/play.svg";
import PauseIcon from "@/assets/icons/pause-msg.svg";
import xIcon from "@/assets/icons/x.svg";
import hibanot from "@/assets/hibanot.mp3";
import PhoneAudioBars from "@/components/ui/widgets/PhoneAudioBars";
export default {
  name: "PhonePop",
  components: {PhoneAudioBars},
  props : {
    notifications: {type: Array, default: () => [] }
  },
  data() {
    return {
      NotificationsBadgeImage, AudioMessageIcon, AudioMessageReadIcon,
      PlayIcon, xIcon, PauseIcon,
      hibanot,
      timeout: null,
      timePos: [],
      done: [],
      playingNow: null,
      expanded: false
    }
  },
  mounted() {
    this.timeout = setInterval(this.onPlayInt, 100)
  },
  beforeDestroy() {
    clearInterval(this.timeout)
  },

  computed : {

  },

  methods : {
    t,
    donePlaying(i) {
      return (this.done[i])
    },
    togglePhone() {
      this.expanded = !this.expanded
    },
    onPlay(notification, index) {
      if (this.playingNow === index) {
        this.$refs.player[this.playingNow].pause()
        return this.playingNow = null
      }
      else if (this.playingNow !== null) {
        this.$refs.player[this.playingNow].pause()
      }
      if (this.playingNow !== index) {
        this.$refs.player[index].play()
      }
      this.playingNow = index;
    },
    onPlayInt() {
      if (!this.$refs.player) return

      for (let i = 0; i < this.$refs.player.length; i++) {
        let {currentTime, duration} = this.$refs.player[i]
        this.$set(this.timePos,i, Math.round(currentTime/duration*100))
        if (this.timePos[i] >= 1) {
          this.done[i] = true
          if (this.timePos[i] >= 100) {
            this.playingNow = null
          }
        }
      }
    }
  },


}
</script>
