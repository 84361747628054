<template>

  <div class="results-view">
    <div class="results-screen screen-1">


    <div class="container-m">
    <div class="centered results-intro">
      <h1><small>{{t("Welcome")}}</small><br />{{t("To Ira's Commando")}}</h1>
      <router-link to="/plan/signup" class="btn join">{{t("Join Now")}} <img :src="ArrowLeftIcon" class="spaced" /></router-link>
    </div>

    <div class="reasons">
      <div class="panel soft">
        <div class="floating-logo">
          <img :src="MeditationIcon" style="width: 72px; position: relative; top: 12px" />
        </div>
        <div class="centered">
          <h3>{{t("reason-1-title")}}</h3>
          <p>{{t("reason-1-text")}}</p>
        </div>
      </div>

      <div class="panel soft">
        <div class="floating-logo">
          <img :src="HealthIcon" style="width: 72px; position: relative; top: 12px" />
        </div>
        <div class="centered">
          <h3>{{t("reason-2-title")}}</h3>
          <p>{{t("reason-2-text")}}</p>
        </div>
      </div>

    </div>
    <router-link to="/plan/signup" class="btn join">{{t("Join Now")}} <img :src="ArrowLeftIcon" class="spaced" /></router-link>
  </div>
  </div>
  <div class="results-screen screen-2">
    <div class="container-m">


    <div class="reasons included">
      <div class="panel soft">

      <div class="header centered">
        <h3>{{t("So, what's included in the plan?")}}</h3>
        <p>{{t("included-text")}}</p>
      </div>
      </div>

      <div class="panel soft">
        <div class="floating-logo">
          <b>1</b>
        </div>
        <div class="centered">
          <h3>{{t("included-1-title")}}</h3>
          <p>{{t("included-1-text")}}</p>
        </div>
      </div>

      <div class="panel soft">
        <div class="floating-logo">
          <b>2</b>
        </div>
        <div class="centered">
          <h3>{{t("included-2-title")}}</h3>
          <p>{{t("included-2-text")}}</p>
        </div>
      </div>

      <div class="panel soft">
        <div class="floating-logo">
          <b>3</b>
        </div>
        <div class="centered">
          <h3>{{t("included-3-title")}}</h3>
          <p>{{t("included-3-text")}}</p>
        </div>
      </div>

    </div>
  </div>
</div>
    <div class="results-screen screen-3">
      <div class="container-m">


    <router-link to="/plan/signup" class="btn join">{{t("Join Now")}} <img :src="ArrowLeftIcon" class="spaced" /></router-link>

    <div class="about-plan">
      <div class="centered">
        <img class="team-image rounded" :src="Team1Image" />
        <h3>{{t("Hi, I'm Ira Dolfin")}}</h3>
        <p style="white-space: pre-line; text-align: start">{{t("ira-intro-1-text")}}</p>
        <a href="https://instagram.com/iradolfin" target="_blank" class="instagram-link"><img :src="InstagramIcon" /> <p>@iradolfin</p></a>

      </div>

      <router-link to="/plan/signup" class="btn join raw">{{t("Join me now")}} <img :src="ArrowLeftIcon" class="spaced" /></router-link>

    </div>

    <div class="centered" style="display: none">
      <h3>{{t("Meet Ira's Commando professional team")}}</h3>
      <div class="team-member">
        <img class="team-image" :src="Team2Image" />
        <h3>{{t("team-member-1-name")}}</h3>
        <p>{{t("team-member-1-text")}}</p>
        <a href="https://instagram.com/kananda_yoga" target="_blank" class="instagram-link"><img :src="InstagramIcon" /> <p>@kananda_yoga</p></a>
      </div>
      <div class="team-member">
        <img class="team-image" :src="Team3Image" />
        <h3>{{t("team-member-2-name")}}</h3>
        <p>{{t("team-member-2-text")}}</p>
        <a href="https://instagram.com/ofrilaski" target="_blank" class="instagram-link"><img :src="InstagramIcon" /> <p>@ofrilaski</p></a>
      </div>
      <div class="team-member">
        <img class="team-image" :src="Team4Image" />
        <h3>{{t("team-member-3-name")}}</h3>
        <p>{{t("team-member-3-text")}}</p>
        <a href="https://instagram.com/maya_hatzor" target="_blank" class="instagram-link"><img :src="InstagramIcon" /> <p>@maya_hatzor</p></a>
      </div>
    </div>


    <div class="qa">
      <h3 class="centered">{{t('Questions and answers')}}</h3>
      <Qa :items="QAData" />
    </div>

    <router-link to="/plan/signup" class="btn join">{{t("Join Now")}} <img :src="ArrowLeftIcon" class="spaced" /></router-link>

    <PoweredBy />
    </div>

  </div>
</div>

</template>

<script>
import t from '@/lib/Locale';
import ArrowLeftIcon from "@/assets/icons/results/arrow-left.svg";
import MeditationIcon from "@/assets/icons/results/dumbbell.svg";
import ScheduleIcon from "@/assets/icons/results/schedule.svg";
import HealthIcon from "@/assets/icons/results/settings.svg";
import InstagramIcon from "@/assets/icons/results/instagram.svg";

import Team1Image from "@/assets/images/ira-photo.jpg";
import Team2Image from "@/assets/images/team/2.png";
import Team3Image from "@/assets/images/team/3.png";
import Team4Image from "@/assets/images/team/4.png";
import Qa from "@/components/ui/widgets/Qa";
import QAData from "@/lib/docs/QAData";
import PoweredBy from "@/components/ui/widgets/PoweredBy.vue";



export default {
  name: "Results",
  components: {PoweredBy, Qa},
  props: ['stats','invoice'],
  data() {
    return {
      QAData,
      ArrowLeftIcon, MeditationIcon, ScheduleIcon, HealthIcon, InstagramIcon,
      Team1Image, Team2Image, Team3Image, Team4Image,

      savedStats : null,
      plans: []
    }
  },


  mounted() {

    if (!localStorage.getItem('QuizAnswers')) {
      window.location.href = '/quiz';
      return false;
    }

  },

  methods: {
    t,
    scrollToPurchase() {
      window.scrollTo({
        top: document.querySelector('#purchase-plan').offsetTop,
        behavior: 'smooth'
      });
    },

  },
  computed : {
  }
}
</script>
