import ReduceStressIcon from "@/assets/quiz/yoga.png";
import ImproveHealthIcon from "@/assets/quiz/heart.png";
import LoseWeightIcon from "@/assets/quiz/diet-food.png";
import ToneUpIcon from "@/assets/quiz/weight-loss.png";
import GainMuscleIcon from "@/assets/quiz/bicep.png";
import StayFitAfterPregnancyIcon from "@/assets/quiz/pregnant-woman.png";

import BeginnerIcon from "@/assets/quiz/happy-baby.png";
import IntermediateIcon from "@/assets/quiz/kid.png";
import AdvancedIcon from "@/assets/quiz/smiling-face-sunglasses.png";

import FullBodyIcon from "@/assets/quiz/gymnastics.png";
import BootyIcon from "@/assets/quiz/body-butt.png";
import AbsIcon from "@/assets/quiz/woman-abs.png";
import LegsIcon from "@/assets/quiz/legs.png";
import ArmsIcon from "@/assets/quiz/bicep.png";

import WeightsIcon from "@/assets/quiz/dumbbell.png";
import ChairIcon from "@/assets/quiz/office-chair.png";
import RubberBandIcon from "@/assets/quiz/skipping-rope.png";
import CubeIcon from "@/assets/quiz/cube.png";

export default [
  {
    prop: "goal",
    tab: "Your Goal",
    tabMobile: "Goal",
    question: "What is your Goal?",
    info: "",
    type: "multiple",
    mandatory: true,
    answers: [
      { value: "reduce-stress", label: "Reduce Stress", icon : ReduceStressIcon },
      { value: "improve-health", label: "Improve Health", icon : ImproveHealthIcon },
      { value: "lose-weight", label: "Lose Weight", icon : LoseWeightIcon },
      { value: "tone-up", label: "Tone Up", icon : ToneUpIcon },
      { value: "gain-muscle", label: "Gain Muscle", icon : GainMuscleIcon },
      { value: "stay-fit-after-pregnancy", label: "Stay Fit After Pregnancy", icon : StayFitAfterPregnancyIcon },
    ]
  },
  {
    prop: "level",
    tab: "Your Level",
    tabMobile: "Level",
    question: "What is your Level?",
    info: "",
    type: "squares",
    answers: [
      { value: "beginner", label: "Beginner", icon : BeginnerIcon },
      { value: "intermediate", label: "Intermediate", icon : IntermediateIcon },
      { value: "advanced", label: "Advanced", icon : AdvancedIcon },
    ]
  },
  {
    prop: "training-number",
    tab: "Training Number",
    tabMobile: "Training Number",
    question: "How many times a week do you want to train?",
    info: "",
    type: "squares",
    mandatory: true,
    answers: [
      { value: "1", label: "1"},
      { value: "2", label: "2"},
      { value: "3", label: "3"},
      { value: "4", label: "4"},
      { value: "5", label: "5"},
      { value: "6", label: "6"},
    ]
  },
  {
    prop: "training-days",
    tab: "Training Days",
    tabMobile: "Training Days",
    question: "What days do you want to train?",
    info: "",
    type: "multiple",
    amount: "training-number",
    mandatory: true,
    answers: [
      { value: "sunday", label: "Sunday"},
      { value: "monday", label: "Monday"},
      { value: "tuesday", label: "Tuesday"},
      { value: "wednesday", label: "Wednesday"},
      { value: "thursday", label: "Thursday"},
      { value: "friday", label: "Friday"},
      { value: "saturday", label: "Saturday"},
    ]
  },
  {
    prop: "body-parts",
    tab: "Body Parts",
    tabMobile: "Body Parts",
    question: "What body parts do you want to train?",
    toggleAll: "full-body",
    info: "",
    type: "multiple",
    mandatory: true,
    answers: [
      { value: "full-body", label: "Full Body", icon : FullBodyIcon },
      { value: "booty", label: "Booty", icon : BootyIcon },
      { value: "abs", label: "Abs", icon : AbsIcon },
      { value: "legs", label: "Legs", icon : LegsIcon },
      { value: "arms", label: "Arms", icon : ArmsIcon },
    ]
  },
  {
    prop: "gear",
    tab: "Gear",
    tabMobile: "Gear",
    question: "What gear do you have?",
    info: "",
    type: "multiple",
    answers: [
      { value: "weights", label: "Weights", icon : WeightsIcon },
      { value: "chair", label: "Chair", icon : ChairIcon },
      { value: "rubber-band", label: "Rubber Band", icon: RubberBandIcon },
      { value: "cube", label: "Cube", icon: CubeIcon },
    ]
  }
]
