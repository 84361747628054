import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import routes from '@/lib/routes'
import {initFito} from "@/lib/fito";
import './registerServiceWorker'

export const CLIENT_VERSION = 1.2;

(async () => {

  const router = new VueRouter({
    mode: 'history',
    routes,
  });
  await initFito('ira_commando_prod', process.env.VUE_APP_API_URL, CLIENT_VERSION, () => { setTimeout(() => router.push('/old-version'),10) });

  Vue.use(VueRouter);
  Vue.config.productionTip = false

  new Vue({
    router,
    render: h => h(App),
  }).$mount('#app')

})();
