<template>
  <div class="quiz-content">
    <div class="quiz-main">
      <h3>{{t(question)}}</h3>
      <div class="info" v-if="info">
        <p>{{t(info)}}</p>
      </div>
      <div :class="'quiz-squares'+(type ? ' '+type:'')" v-if="type === 'squares'">
        <div class="diagonal-wrap rad" v-for="(option, i) in answers" :key="option.label">
          <a class="square" @click="onAnswer(option.value)" :tabindex="i" href="javascript:void(0)" v-if="testOption(option)">
            <div class="square-icon" v-if="option.icon"><img :src="option.icon" /></div>
            <div class="square-title">{{t(option.label)}}</div>
          </a>
        </div>
      </div>
      <div v-if="type === 'multiple'" class="quiz-checkboxes">
        <div v-for="(option, i) in answers" :key="option.label">
          <label :tabindex="i">
            <div class="checkbox-icon" v-if="option.icon"><img :src="option.icon" /></div>
            <input type="checkbox" :value="option.value" @change="() => onMultiToggle(option.value)" :checked="multiAnswers.includes(option.value)" />
            <span>{{t(option.label)}}</span>
          </label>
        </div>
        <div :class="'btn' + (isDisabled()?' disabled':'')" @click="submitMulti">{{t("Next")}}</div>
      </div>
      <div v-if="type === 'slider'">
        <input type="range" :max="max" :value="sliderValue" @input="onSliderChange" @change="onSliderChange">
        <div class="slider-value">{{sliderValue}}</div>
        <div class="btn" @click="submitSlider">{{t("Next")}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import t from "@/lib/Locale";

export default {
  name: "QuizQuestion",
  props: ["question","answers","onAnswer","onToggleMultiAnswer", "info", "list", "type", "results", "goto","min","max","amount","mandatory","prop","toggleAll"],
  data() {
    return {
      sliderValue: 1,
      multiAnswers : [],
    }
  },
  computed : {
  },
  methods: {
    t,
    isDisabled() {
      let amt = this.amount
      if (amt && this.results[amt]) {
        amt = this.results[amt]*1
      }
      return (this.type === 'multiple' && this.mandatory && !this.multiAnswers.length) ||
        (this.type === 'multiple' && amt && this.multiAnswers.length !== amt)
    },
    submitMulti() {
      if (this.isDisabled()) return
      this.onAnswer(this.multiAnswers)
    },
    testOption(option) {
      if (!option.test) return true;
      return (option.test(this.results))
    },
    onMultiToggle(answer) {

      if (this.toggleAll && this.toggleAll === answer) {
        console.log(this.toggleAll, answer);
        if (this.multiAnswers.length === this.answers.length) {
          // this.multiAnswers = []
          this.$set(this, 'multiAnswers', [])
        } else {
          // this.multiAnswers = this.answers.map(a => a.value)
          this.$set(this, 'multiAnswers', this.answers.map(a => a.value))
        }
        return
      }

      if (this.multiAnswers.includes(answer)) {
        this.multiAnswers = this.multiAnswers.filter(a => a !== answer)
      } else {
        this.multiAnswers.push(answer)
      }
    },
    onSliderChange(e) {
      this.sliderValue = e.target.value
    },
    submitSlider() {
      this.onAnswer(this.sliderValue)
    }
  },
  watch : {
    question() {
      this.multiAnswers = []
    },
    results() {
      console.log(this.results,"ja")
      this.$set('multiAnswers', this.results)
    }
  }
}
</script>

