const events = {}

export const Pubsub = {
    subscribe: function (eventName, fn) {
        events[eventName] = events[eventName] || [];
        events[eventName].push(fn);
    },

    unsubscribe: function (eventName, fn) {
        if (events[eventName]) {
            for (let i = 0; i < events[eventName].length; i++) {
                if (events[eventName][i] === fn) {
                    events[eventName].splice(i, 1);
                }
            }
        }
    },

    publish: function (eventName, data) {
        if (events[eventName]) {
            events[eventName].forEach(function(fn) {
                fn(data);
            });
        }
    }
}

window.Pubsub = Pubsub
