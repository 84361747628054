<template>
  <div :class="'main-menu' + (active?' active':'')" @click="closeMenu">
    <div class="main-menu-inner" @click="(e) => e.stopPropagation()">
      <div class="logo">
        <a href="/"><img :src="logo" /></a>
      </div>
      <ul class="items" v-if="loggedIn">
        <li><a @click="goto('/plan/yoga')"><img :src="yogaIcon" />{{t("Yoga Trainings")}}</a></li>
        <li><a @click="goto('/plan/recipes')"><img :src="recipesIcon" />{{t("Recipes")}}</a></li>
        <li><a @click="goto('/plan/conscious')"><img :src="consciousIcon" />{{t("Conscious Eating")}}</a></li>
        <li><a @click="goto('/plan/exclusives')"><img :src="exclusivesIcon" />{{t("Exclusives")}}</a></li>
      </ul>
      <ul class="items footer">
        <li><a @click="goto('/plan/favorites')" v-if="loggedIn"><img :src="favoritesIcon" />{{t("My Favorites")}}</a></li>
        <li><a @click="goto('/contact')" class="bolder"><img :src="contactIcon" />{{t("Contact us")}}</a></li>
      </ul>
      <div class="footer sub-footer" v-if="loggedIn && subscriber">
        <Flexy>
          <img :src="AvatarImage" class="avatar" />
          <div class="flex-1">
            <h3>{{subscriber.first_name + ' '+subscriber.last_name}}</h3>
            <p>{{subscriber.email}}</p>
          </div>
          <img :src="logoutIcon" @click="doLogout" />
        </Flexy>
      </div>
    </div>
    <div class="main-menu-close" @click="closeMenu"><img :src="xIcon" /></div>
  </div>
</template>

<script>
import t from '@/lib/Locale';

import logo from '@/assets/images/headline.png';
import yogaIcon from '@/assets/icons/menu/yoga.svg';
import recipesIcon from '@/assets/icons/menu/recipes.svg';
import consciousIcon from '@/assets/icons/menu/conscious.svg';
import exclusivesIcon from '@/assets/icons/menu/exclusives.svg';
import favoritesIcon from '@/assets/icons/menu/favorites.svg';
import logoutIcon from '@/assets/icons/menu/logout.svg';
import accountIcon from '@/assets/icons/menu/user.svg';
import contactIcon from '@/assets/icons/menu/support.svg';
import xIcon from '@/assets/icons/menu/x.svg';
import AvatarImage from '@/assets/images/avatar.svg';
import Flexy from "@/components/ui/widgets/Flexy";


export default {
  name: "MainMenu",
  components: {Flexy},
  props: ['closeMenu', 'active', 'loggedIn','subscriber','onLogout'],
  data() {
    return {
      logo, xIcon, AvatarImage, logoutIcon,
      yogaIcon, recipesIcon, consciousIcon, exclusivesIcon,
      favoritesIcon, accountIcon, contactIcon
    }
  },
  methods : {
    t,
    goto(path) {
      if (this.$route.path !== path) this.$router.push(path)
      this.closeMenu()
    },
    doLogout() {
      this.onLogout()
      this.closeMenu()
    }
  }
}
</script>
