<template>
  <div class="container-m">

    <div class="panel" v-if="waitingForPaymentPage">
      <h3>{{t("One moment")}}</h3>
      <p>{{t("Transferring you to the payment page...")}}</p>
    </div>

    <SignupForm :on-submit="onSubmitDetails" :details="details" :error="error" v-if="!submittedDetails && !waitingForPaymentPage">
      <div class="centered">
        <h2>{{t("Sign Up")}}</h2>
        <p>{{t("In order to join you need to sign up first")}}</p>
      </div>
    </SignupForm>

    <ChoosePlanForm v-if="submittedDetails && token && !waitingForPaymentPage" :plans="plans" :on-cancel="cancelChoosePlan" :on-select="onChoosePlan" @apply-coupon="onApplyCoupon" :overridePrice="overridePrice" />
    <PoweredBy />
  </div>
</template>

<script>
import t from '@/lib/Locale';
import SignupForm from "@/components/forms/plan/SignupForm";
import Fito from "@/lib/fito";
import ChoosePlanForm from "@/components/forms/plan/ChoosePlanForm";
import PoweredBy from "@/components/ui/widgets/PoweredBy.vue";
import {Pubsub} from "@/lib/Pubsub";

const couponPrice = 49.90;

export default {
  name: "Signup",
  props: ["meta"],
  components: {PoweredBy, ChoosePlanForm, SignupForm},
  data() {
    return {
      details : {},
      plans : [],
      token: null,
      submittedDetails: false,
      waitingForPaymentPage: false,
      error: '',
      couponApplied: false,
    }
  },

  async mounted() {
    let token = this.$route.params && this.$route.params.code ?
        this.$route.params.code :
        localStorage.getItem('SignupToken');

    if (token) {
      this.details = await Fito().Signup.RecoverDetails(token)
      this.token = token;
    }

    this.plans = await Fito().Signup.GetPlans();

    const answers = JSON.parse(localStorage.getItem('QuizAnswers'))
    if (!token && (!answers || !answers.goal)) return this.$router.push('/')

    this.initGoogleLogin()

  },

  computed : {
    overridePrice() {
      return this.couponApplied ? couponPrice : null;
    }
  },

  methods : {
    t,
    initGoogleLogin() {
      const google = window.google;
      if (typeof google === 'undefined') return;

      google.accounts.id.initialize({
        client_id: "748435640007-4sjg2r4racps2aunhrqiab4d5lqf6vv4.apps.googleusercontent.com",
        callback: (response) => Pubsub.publish('google-login', response)
      });
      google.accounts.id.renderButton(
        document.getElementById("google-signin"),
        { theme: "outline", size: "large" }  // customization attributes
      );
      google.accounts.id.prompt();
    },
    async onApplyCoupon(coupon) {
      if (coupon.toLowerCase() === "together24") {
        console.log("Coupon applied!")
        await Fito().Signup.setCouponCode(this.token, 4, coupon)
        this.couponApplied = true;
      }
      else {
        console.log("Invalid coupon!")
      }
    },

    async onSubmitDetails(first_name, last_name, email, password) {

      if (password.length < 8) {
        this.error = t('Your password must contain at least 8 letters')
        return false;
      }

      if (this.submittedDetails) return;
      this.submittedDetails = true
      const answers = JSON.parse(localStorage.getItem('QuizAnswers'))

      this.details = {
        first_name, last_name, email, password
      }

      const res = await Fito().Signup.Signup(first_name, last_name, email, password, "f", this.token);
      if (res.token) {
        localStorage.setItem('SignupToken', res.token)
        this.token = res.token;

        // Set signup stats
        if (answers && answers.goal) {
          await Fito().RunAction('set-signup-stats', {
            goal: answers.goal, level: answers.level, 'training-number': answers['training-number'],
            'training-days': answers['training-days'], 'body-parts': answers['body-parts'], 'gear': answers['gear']
          });

        }
      }
      else {
        console.error("Signup Error!", res)
        this.error = res.error;
        alert("Something went wrong, try again!")
      }
    },

    cancelChoosePlan() {
      this.submittedDetails = false
      console.log(this.details)
    },

    async onChoosePlan(plan) {
      const price = this.couponApplied ? couponPrice : plan.price;
      const tokenData = await Fito().Signup.GetTransactionToken(plan.name, this.details.first_name + ' ' + this.details.last_name, this.details.email, this.details.phone||'', price, this.token)

      await Fito().Signup.setPlan(this.token, plan.id)
      this.waitingForPaymentPage = true
      setTimeout(() => {
        // console.log(tokenData.url + suffix)
        if (tokenData.url) window.location.href = tokenData.url
        else {
          this.waitingForPaymentPage = false
          console.log("Something went wrong, try again!")
        }
      }, 100)
    }
  }
}
</script>
