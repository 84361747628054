<template>
  <div class="choose-plan-form">
    <span class="back-link raw" v-if="onCancel">
      <Flexy><img :src="ArrowRightIcon" @click="onCancel" /> <p>{{t('Choose the right plan for you')}}</p></Flexy>
    </span>
    <div class="boxes light plans">
      <Box v-for="(plan, ii) in myPlans" :key="ii" :title="plan.name" :action-icon="ChoosePlanIcon" @click="() => onSelect(plan)">
        <div class="prices">
          <div class="crossed-price" v-if="plan.full_price">{{t("{0} nis", parseFloat(plan.full_price).toFixed(2))}}</div>
          <div class="price">{{t("{0} nis/month", parseFloat(overridePrice ? overridePrice : plan.price).toFixed(2))}}</div>
          <div class="trial">{{t("{0} days free trial", FREE_TRIAL_DAYS)}}</div>
          <div class="description" v-if="plan.description">{{plan.description}}</div>
        </div>
      </Box>

      <div class="centered small">
        {{t("You can always cancel your plan in your user profile")}}
      </div>
      <br/>

      <Box>
        <div class="centered">
          <h3>{{t("Do you have a coupon code?")}}</h3>
          <br />
          <button v-if="!enterCoupon" class="btn btn-primary" @click="enterCoupon = true">{{t("Enter your coupon code")}}</button>
          <div class="row" v-if="enterCoupon">
            <input type="text" :placeholder="t('Enter your coupon code')" v-model="coupon" />
            <button class="btn btn-primary" @click="applyCoupon">{{t('Apply')}}</button>
          </div>
        </div>
      </Box>
    </div>
    <div>

    </div>
  </div>
</template>

<script>
import t from "@/lib/Locale";
import ArrowRightIcon from '@/assets/icons/arrow-right.svg';
import ChoosePlanIcon from '@/assets/icons/choose-plan.svg';
import Flexy from "@/components/ui/widgets/Flexy";
import Box from "@/components/ui/widgets/Box";

const FREE_TRIAL_DAYS = 7  // <-- Set the free trial days here

export default {
name: "ChoosePlanForm",
  components: {Box, Flexy},
  props : {
    plans : {type: Array},
    onSelect : {type: Function},
    onCancel : {type: Function},
    overridePrice : {type: Number},
  },

  data : function() {
    return {
      ArrowRightIcon, ChoosePlanIcon, FREE_TRIAL_DAYS,

      first_name: '',
      last_name: '',
      email: '',
      password: '',
      remember: false,
      enterCoupon : false,

      coupon : '',
    }
  },

  mounted() {
  },

  computed : {
    myPlans() {
      return this.plans
    }
  },

  methods : {
    t,

    applyCoupon() {
      if (this.coupon) {
        this.$emit('apply-coupon', this.coupon)
      }
    },

    trySubmit : function() {
      if (this.onSubmit) {
        this.onSubmit(this.first_name, this.last_name, this.email, this.password);
      }
    },

  },


}
</script>
