<template>
  <div class="container-m">
    <div class="centered">
      <h2>{{t("Sign Up Complete")}}</h2>
      <p>{{t("You can download our app and get started!")}}</p>
      <div id="links">
        <a href="https://apps.apple.com/us/app/%D7%94%D7%A7%D7%95%D7%9E%D7%A0%D7%93%D7%95-%D7%A9%D7%9C-%D7%90%D7%99%D7%A8%D7%94/id6471570785">אייפון</a>
        <a href="https://play.google.com/store/apps/details?id=com.fito.ira&hl=en">אנדרואיד</a>
      </div>
    </div>
  </div>
</template>

<script>
import t from '@/lib/Locale';
import Fito from '@/lib/fito'

export default {
  name: "Signup",
  async mounted() {
    await this.completeSignup()
  },

  methods : {
    t,
    async completeSignup() {
      const signupToken = localStorage.getItem('SignupToken');
      const {res, token} = await Fito().Signup.CompleteSignup(signupToken)
      if (res === 'ok') {
        console.log("New token:", token)
        localStorage.setItem("login-token", token);
        localStorage.removeItem('SignupToken')

        setTimeout(() => {
          window.location.href = '/app'
        }, 5000)
      }
    }
  }
}
</script>
