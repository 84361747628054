<template>
  <div>
    <ExpiredForm v-if="expired" />
    <div v-if="suspended">
      <div class="boxes light">
        <Box :title="t('Account Suspended')" link="/contact"><p>{{suspended}}</p></Box>
      </div>
    </div>
    <div class="boxes light purple" v-if="!expired && !suspended">
      <router-link :to="'/plan/conscious/'+list.name" class="box" v-for="(list, index) in lists" :key="index">
        <div class="flexy">
          <div class="flex-1">
            <img v-if="list.thumbnail" :src="list.thumbnail" class="icon" />
            <h3>{{list.display_name}}</h3>
            <p>{{t("Insights and ideas that can improve your day")}}</p>
          </div>
          <div>
            <img :src="ChevronIcon" />
          </div>
        </div>
      </router-link>
      <router-link to="/plan/conscious/quiz" class="box">
        <div class="flexy">
          <div class="flex-1">
              <img :src="QAIcon" class="icon" />
              <h3>{{t("Interactive Quiz")}}</h3>
              <p>{{t("Create your own analysis about your eating habits")}}</p>
            </div>
            <div>
              <img :src="ChevronIcon" />
            </div>
          </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import QAIcon from '@/assets/icons/conscious/question.svg';
import VideoIcon from '@/assets/icons/conscious/video.svg';
import TipsIcon from '@/assets/icons/conscious/chat.svg';
import ChevronIcon from '@/assets/icons/chevron.svg';
import t from '@/lib/Locale';
import Fito from "@/lib/fito";
import ExpiredForm from "@/components/forms/ExpiredForm";
import Box from "@/components/ui/widgets/Box";

const folderName = "conscious";

export default {
  name: "Conscious",
  components: { Box, ExpiredForm},
  data() {
    return {
      TipsIcon,
      VideoIcon,
      QAIcon,
      ChevronIcon,

      expired: false,
      suspended: false,
      lists : []
    }
  },

  async mounted() {
    const login = await Fito().Login.safeCheckLogin()
  if (!login) return this.$router.push('/plan/login')

    if (login.status === 'expired') this.expired = true;
    if (Fito().suspended) { this.suspended = Fito().suspended }


    const mediaLists = await Fito().Plan.getMediaLists()
    const lists = mediaLists.filter(it => it.folder === folderName).sort((a,b) => a.sort < b.sort ? -1:1);
    if (lists.length > 0) {
      this.lists = lists;
    }
  },

  methods : {
    t,
  }
}
</script>
