<template>
  <div class="signup-form">
    <slot></slot>
    <div class="login-buttons">
      <div id="google-signin"></div>
<!--      <button tabindex="3" class="btn secondary" ref="googleLogin"><img :src="GoogleIcon" /> {{t('Sign in with Google')}}</button>-->
    </div>
    <div class="panel raw">

    <app-form :on-submit="trySubmit" class="panel-form">
      <InputWrapper v-model="first_name" :label="t('First Name')" type="name" :placeholder="t('First Name')"/>
      <InputWrapper v-model="last_name" :label="t('Last Name')" type="name" :placeholder="t('Last Name')" />
      <InputWrapper v-model="email" :label="t('Email')" type="email" :placeholder="t('E-mail Address')" />
      <InputWrapper v-model="password" :label="t('Password')" type="password" :placeholder="t('Your password must contain at least 8 letters')" />

      <small>{{t("By signing up you confirm you agree to our")}} <a href="/terms" target="_blank">{{t("Terms of use")}}</a></small>
      <div class="login-buttons">
        <button v-on:click="trySubmit" tabindex="3" :class="'btn 3'+(canSubmit?' active':' disabled')">{{t('Sign me up')}}</button>
      </div>

      <div v-if="error" class="error">
        <b>{{t('Error')}}</b>
        <div class="error-body">{{t(error)}}</div>
      </div>
    </app-form>
    </div>
  </div>
</template>

<script>
import AppForm from "@/components/forms/AppForm";
import CheckIcon from "@/assets/icons/check.svg";
import GoogleIcon from "@/assets/icons/external/google.svg";
import FacebookIcon from "@/assets/icons/external/facebook.svg";
import t from "@/lib/Locale";
import InputWrapper from "@/components/ui/widgets/InputWrapper";
import {Pubsub} from "@/lib/Pubsub";
import jwtDecode from "jwt-decode";

export default {
name: "SignupForm",
  components: {InputWrapper, AppForm },
  props : {
    details : {type: Object},
    onSubmit : {type: Function},
    onForgot : {type: Function},
    error : {type: String},
  },

  data : function() {
    return {
      CheckIcon,
      GoogleIcon,
      FacebookIcon,

      first_name: '',
      last_name: '',
      email: '',
      password: '',
      remember: false,
    }
  },

  mounted() {
      this.reloadDetails();
      Pubsub.subscribe('google-login', this.onGoogleLogin.bind(this))

  },

  methods : {
    t,

    onGoogleLogin(googleUser) {
      const credential = jwtDecode(googleUser.credential)
      console.log(credential)
      this.first_name = credential.given_name
      this.last_name = credential.family_name
      this.email = credential.email
    },

    isValid : function(val, type) {
      switch (type) {
        case 'email': return val.indexOf('@') > 0 && val.lastIndexOf(".") > val.lastIndexOf('@') && val.lastIndexOf(".") < val.length-2;
        case 'password': return val.length >= 8;
        case 'name': return val.length >= 2;
      }
      return false;
    },

    trySubmit : function() {
      if (this.onSubmit) {
        this.onSubmit(this.first_name, this.last_name, this.email, this.password);
      }
    },

    forgot : function() {
      if (this.onForgot) this.onForgot();
    },

    reloadDetails() {
      if (this.details.first_name) this.first_name = this.details.first_name
      if (this.details.last_name) this.last_name = this.details.last_name
      if (this.details.email) this.email = this.details.email
    }
  },

  computed : {
    canSubmit : function() {
      return this.isValid(this.email, 'email') && this.isValid(this.password,'password') && this.isValid(this.first_name,'name') && this.isValid(this.last_name,'name')
    },
  },

  watch : {
    details() {
      this.reloadDetails()
    }
  }

}
</script>
