import Landing from "@/components/views/landing/Landing";
import Quiz from "@/components/views/landing/Quiz";
import Contact from "@/components/views/common/Contact";
import Results from "@/components/views/landing/Results";
import Login from "@/components/views/login/Login";
import MyProfile from "@/components/views/plan/MyProfile";
import YogaHome from "@/components/views/plan/yoga/YogaHome";
import YogaVideos from "@/components/views/plan/yoga/YogaVideos";
import YogaPractice from "@/components/views/plan/yoga/YogaPractice";
import ConsciousMediaList from "@/components/views/plan/conscious/ConsciousMediaList";
import ConsciousHome from "@/components/views/plan/conscious/ConsciousHome";
import ConsciousPractice from "@/components/views/plan/conscious/ConsciousPractice";
import ConsciousQuizIntro from "@/components/views/plan/conscious/ConsciousQuizIntro";
import ConsciousQuiz from "@/components/views/plan/conscious/ConsciousQuiz";
import ExclusivesHome from "@/components/views/plan/exclusives/ExclusivesHome";
import RecipesHome from "@/components/views/plan/recipes/RecipesHome";
import ExclusivesItem from "@/components/views/plan/exclusives/ExclusivesItem";
import FavoritesHome from "@/components/views/plan/favorites/FavoritesHome";
import Signup from "@/components/views/signup/Signup";
import YogaBonus from "@/components/views/plan/yoga/YogaBonus";
import RecipeDetails from "@/components/views/plan/recipes/RecipeDetails";
import RecipesCategory from "@/components/views/plan/recipes/RecipesCategory";
import RecipesCategories from "@/components/views/plan/recipes/RecipesCategories";
import SignupComplete from "@/components/views/signup/SignupComplete";
import RecipeSearch from "@/components/views/plan/recipes/RecipeSearch";
import RecipeFilters from "@/components/views/plan/recipes/RecipeFilters";
import ResetPassword from "@/components/views/login/ResetPassword";
import RenewPlan from "@/components/views/plan/RenewPlan";
import MenusHome from "@/components/views/plan/menus/MenusHome";
import MenusInstructions from "@/components/views/plan/menus/MenusInstructions";
import MenusDailyMenu from "@/components/views/plan/menus/MenusDailyMenu";
import MenusSelectMeal from "@/components/views/plan/menus/MenusSelectMeal";
import MenusMealDetails from "@/components/views/plan/menus/MenusMealDetails";
import MenusPlanDetails from "@/components/views/plan/menus/MenusPlanDetails";
import MenusChoosePlan from "@/components/views/plan/menus/MenusChoosePlan";
import MenusMealsFilters from "@/components/views/plan/menus/MenusMealsFilters";
import LivePractice from "@/components/views/plan/yoga/LivePractice";
import VersionError from "@/components/views/common/VersionError";

export default [
    // Landing
    { path: '/', component: Landing, keepAlive: true },
    { path: '/quiz', component: Quiz, keepAlive: true },
    { path: '/contact', component: Contact, meta: { form: true }, keepAlive: true },
    { path: '/results', component: Results, keepAlive: true },
    { path: '/recover-password/:code', component: ResetPassword, meta: { form: true }, keepAlive: true },
    { path: '/old-version', component: VersionError, meta: { form: true }, keepAlive: true },

    // Plan
    { path: '/plan/login', component: Login, meta: { form: true }, keepAlive: true },
    { path: '/plan/signup', component: Signup, keepAlive: true },
    { path: '/plan/signup/:code', component: Signup, keepAlive: true },
    { path: '/plan/signup-complete', component: SignupComplete, keepAlive: true },
    { path: '/plan/contact', component: Contact, meta: { form: true }, keepAlive: true },
    { path: '/plan/profile', component: MyProfile, keepAlive: true },
    { path: '/plan/renew', component: RenewPlan,  keepAlive: true },

    // live event
    { path: '/plan/live-stream/:id', component: LivePractice, keepAlive: true},

    // yoga
    { path: '/plan/yoga/bonus', component: YogaBonus, keepAlive: true},
    { path: '/plan/yoga/bonus/:list', component: YogaVideos, meta: {backLink: '/plan/yoga/bonus'}, keepAlive: true},
    { path: '/plan/yoga/:list', component: YogaVideos, meta : { timeline: true }, keepAlive: true},
    { path: '/plan/yoga/:list/:id', component: YogaPractice, keepAlive: true },
    { path: '/plan/yoga', component: YogaHome, keepAlive: true },

    // menus
    { path: '/plan/menus', component: MenusHome, keepAlive: true, meta: { form: true } },
    { path: '/plan/menus/instructions', component: MenusInstructions, keepAlive: true },
    { path: '/plan/menus/daily', component: MenusDailyMenu, keepAlive: true },
    { path: '/plan/menus/select-meal', component: MenusSelectMeal, keepAlive: true },
    { path: '/plan/menus/meal-filters', component: MenusMealsFilters, keepAlive: true },
    { path: '/plan/menus/meal-details/:id', component: MenusMealDetails, keepAlive: true },
    { path: '/plan/menus/choose-plan', component: MenusChoosePlan, keepAlive: true },
    { path: '/plan/menus/choose-plan/:id', component: MenusPlanDetails, keepAlive: true },

    // recipes
    { path: '/plan/recipes', component: RecipesHome, keepAlive: true },
    { path: '/plan/recipes/view/:id', component: RecipeDetails, keepAlive: true },
    { path: '/plan/recipes/categories', component: RecipesCategories, keepAlive: true },
    { path: '/plan/recipes/categories/:id', component: RecipesCategory, keepAlive: true },
    { path: '/plan/recipes/filters', component: RecipeFilters, keepAlive: true },
    { path: '/plan/recipes/search', component: RecipeSearch, keepAlive: true },
    { path: '/plan/recipes/search/:search', component: RecipeSearch, keepAlive: true },

    // conscious
    { path: '/plan/conscious/videos/:id', component: ConsciousPractice, keepAlive: true},
    { path: '/plan/conscious/quiz/go', component: ConsciousQuiz, keepAlive: true, meta: { form: true }},
    { path: '/plan/conscious/quiz', component: ConsciousQuizIntro, keepAlive: true },
    { path: '/plan/conscious/:list', component: ConsciousMediaList, keepAlive: true },
    { path: '/plan/conscious', component: ConsciousHome, keepAlive: true },

    // exclusives
    { path: '/plan/exclusives', component: ExclusivesHome, keepAlive: true },
    { path: '/plan/exclusives/:id', component: ExclusivesItem, keepAlive: true },

    // favorites
    { path: '/plan/favorites', component: FavoritesHome, keepAlive: true },

    // account
    { path: '/plan/account', component: MyProfile, meta: { form: true }, keepAlive: true },

]
